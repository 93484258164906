/** @format */
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import React, { Component } from "react";
import { useTranslation } from "react-i18next";
// import React, { useRef } from 'react';
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
// language translation

// import en from '../../lang/en.json';

import { ScrollTop } from "primereact/scrolltop";

//page componets
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";

//import css
import "./home.scss";

// images
import appCircle from "../../assets/images/structure/app-icon-circle.svg";
import flwr from "../../assets/images/structure/flwr.svg";
import whatsapp from "../../assets/images/structure/whatsapp.svg";
import listIcon from "../../assets/images/structure/list-icon.svg";
import listIconBlue from "../../assets/images/structure/list-icon-blue.svg";
import fixelloCorporateIcon from "../../assets/images/structure/fixello-corporate-icon.svg";
import fixelloPersonalIcon from "../../assets/images/structure/fixello-personal-icon.svg";
import whyfixelloicon1 from "../../assets/images/structure/why-fixello-icon-1.svg";
import whyfixelloicon2 from "../../assets/images/structure/why-fixello-icon-2.svg";
import whyfixelloicon3 from "../../assets/images/structure/why-fixello-icon-3.svg";
import whyfixelloicon4 from "../../assets/images/structure/why-fixello-icon-4.svg";
import whyfixelloicon5 from "../../assets/images/structure/why-fixello-icon-5.svg";
import whyfixelloicon6 from "../../assets/images/structure/why-fixello-icon-6.svg";
import orderIcon from "../../assets/images/structure/order-icon.svg";
import chooseIcon from "../../assets/images/structure/choose-icon.svg";
import injoyIcon from "../../assets/images/structure/injoy-icon.svg";
import signupIcon from "../../assets/images/structure/signup-icon.svg";
import browseIcon from "../../assets/images/structure/browse-icon.svg";
import placeOfferIcon from "../../assets/images/structure/place-offer-icon.svg";

import homebannerbgimages from "../../assets/images/structure/home-banner-bg-images.png";
import clientPhoneImage1 from "../../assets/images/products/client-phone-image1.png";
import clientPhoneImage2 from "../../assets/images/products/client-phone-image2.png";
import clientPhoneImage3 from "../../assets/images/products/client-phone-image3.png";
import clientPhoneImage4 from "../../assets/images/products/client-phone-image4.png";

import servicePhoneImage1 from "../../assets/images/products/service-phone-image1.png";
import servicePhoneImage2 from "../../assets/images/products/service-phone-image2.png";
import servicePhoneImage3 from "../../assets/images/products/service-phone-image3.png";
import servicePhoneImage4 from "../../assets/images/products/service-phone-image4.png";

import whatwedoimages from "../../assets/images/structure/what-we-do-image.png";
import iphonemultiple from "../../assets/images/products/iphone-multiple-screens-mockup.png";
import clientImage1 from "../../assets/images/products/our-client-image1.png";
import clientImage2 from "../../assets/images/products/our-client-image2.png";
import locationIcon from "../../assets/images/structure/location-icon.svg";
import doubleArrow from "../../assets/images/structure/double-arrow.svg";
import emailIcon from "../../assets/images/structure/email-icon.svg";
import contactIcon from "../../assets/images/structure/contact-icon.svg";
import categoryimage1 from "../../assets/images/products/category-image1.png";
import categoryimage2 from "../../assets/images/products/category-image2.png";
import categoryimage3 from "../../assets/images/products/category-image3.png";
import categoryimage4 from "../../assets/images/products/category-image4.png";

import subcategoryimage1 from "../../assets/images/products/sub-category-image1.png";
import subcategoryimage2 from "../../assets/images/products/sub-category-image2.png";
import subcategoryimage3 from "../../assets/images/products/sub-category-image3.png";
import subcategoryimage4 from "../../assets/images/products/sub-category-image4.png";
import subcategoryimage5 from "../../assets/images/products/sub-category-image5.png";
import subcategoryimage6 from "../../assets/images/products/sub-category-image6.png";
import subcategoryimage7 from "../../assets/images/products/sub-category-image7.png";
import subcategoryimage8 from "../../assets/images/products/sub-category-image8.png";

import subcategoryimage9 from "../../assets/images/products/sub-category-image9.png";
import subcategoryimage10 from "../../assets/images/products/sub-category-image10.png";
import subcategoryimage11 from "../../assets/images/products/sub-category-image11.png";
import subcategoryimage12 from "../../assets/images/products/sub-category-image12.png";
import subcategoryimage13 from "../../assets/images/products/sub-category-image13.png";
import subcategoryimage14 from "../../assets/images/products/sub-category-image14.png";
import subcategoryimage15 from "../../assets/images/products/sub-category-image15.png";
import subcategoryimage16 from "../../assets/images/products/sub-category-image16.png";
import subcategoryimage17 from "../../assets/images/products/sub-category-image17.png";
import subcategoryimage18 from "../../assets/images/products/sub-category-image18.png";
import subcategoryimage19 from "../../assets/images/products/sub-category-image19.png";
import subcategoryimage20 from "../../assets/images/products/sub-category-image20.png";
import subcategoryimage21 from "../../assets/images/products/sub-category-image21.png";
import subcategoryimage22 from "../../assets/images/products/sub-category-image22.png";
import subcategoryimage23 from "../../assets/images/products/sub-category-image23.png";
import subcategoryimage24 from "../../assets/images/products/sub-category-image24.png";
import subcategoryimage25 from "../../assets/images/products/sub-category-image25.png";
import subcategoryimage26 from "../../assets/images/products/sub-category-image26.png";
import subcategoryimage27 from "../../assets/images/products/sub-category-image27.png";
import subcategoryimage28 from "../../assets/images/products/sub-category-image28.png";
import subcategoryimage29 from "../../assets/images/products/sub-category-image29.png";
import subcategoryimage30 from "../../assets/images/products/sub-category-image30.png";
import subcategoryimage31 from "../../assets/images/products/sub-category-image31.png";
import subcategoryimage32 from "../../assets/images/products/sub-category-image32.png";

import subcategoryimage33 from "../../assets/images/products/subcategoryimage33.png";
import subcategoryimage34 from "../../assets/images/products/subcategoryimage34.png";
import subcategoryimage35 from "../../assets/images/products/subcategoryimage35.png";
import subcategoryimage36 from "../../assets/images/products/subcategoryimage36.png";
import subcategoryimage37 from "../../assets/images/products/subcategoryimage37.png";
import subcategoryimage38 from "../../assets/images/products/subcategoryimage38.png";
import subcategoryimage39 from "../../assets/images/products/subcategoryimage39.png";
import subcategoryimage40 from "../../assets/images/products/subcategoryimage40.png";
import subcategoryimage41 from "../../assets/images/products/subcategoryimage41.png";
import subcategoryimage42 from "../../assets/images/products/subcategoryimage42.png";
import subcategoryimage43 from "../../assets/images/products/subcategoryimage43.png";
import subcategoryimage44 from "../../assets/images/products/subcategoryimage44.png";
import subcategoryimage45 from "../../assets/images/products/subcategoryimage45.png";
import subcategoryimage46 from "../../assets/images/products/subcategoryimage46.png";
import subcategoryimage47 from "../../assets/images/products/subcategoryimage47.png";
import subcategoryimage48 from "../../assets/images/products/subcategoryimage48.png";
import subcategoryimage49 from "../../assets/images/products/subcategoryimage49.png";
import subcategoryimage50 from "../../assets/images/products/subcategoryimage50.png";
import subcategoryimage51 from "../../assets/images/products/subcategoryimage51.png";
import subcategoryimage52 from "../../assets/images/products/subcategoryimage52.png";
import subcategoryimage53 from "../../assets/images/products/subcategoryimage53.png";
import subcategoryimage54 from "../../assets/images/products/subcategoryimage54.png";
import subcategoryimage55 from "../../assets/images/products/subcategoryimage55.png";
import subcategoryimage56 from "../../assets/images/products/subcategoryimage56.png";
import subcategoryimage57 from "../../assets/images/products/subcategoryimage57.png";
import subcategoryimage58 from "../../assets/images/products/subcategoryimage58.png";
import subcategoryimage59 from "../../assets/images/products/subcategoryimage59.png";
import subcategoryimage60 from "../../assets/images/products/subcategoryimage60.png";
import subcategoryimage61 from "../../assets/images/products/subcategoryimage61.png";
import subcategoryimage62 from "../../assets/images/products/subcategoryimage62.png";
import subcategoryimage63 from "../../assets/images/products/subcategoryimage63.png";
import subcategoryimage64 from "../../assets/images/products/subcategoryimage64.png";

import subcategoryimage65 from "../../assets/images/products/subcategoryimage65.png";
import subcategoryimage66 from "../../assets/images/products/subcategoryimage66.png";
import subcategoryimage67 from "../../assets/images/products/subcategoryimage67.png";
import subcategoryimage68 from "../../assets/images/products/subcategoryimage68.png";
import subcategoryimage69 from "../../assets/images/products/subcategoryimage69.png";
import subcategoryimage70 from "../../assets/images/products/subcategoryimage70.png";
import subcategoryimage71 from "../../assets/images/products/subcategoryimage71.png";
import subcategoryimage72 from "../../assets/images/products/subcategoryimage72.png";
import subcategoryimage73 from "../../assets/images/products/subcategoryimage73.png";
import subcategoryimage74 from "../../assets/images/products/subcategoryimage74.png";
import subcategoryimage75 from "../../assets/images/products/subcategoryimage75.png";
import subcategoryimage76 from "../../assets/images/products/subcategoryimage76.png";
import subcategoryimage77 from "../../assets/images/products/subcategoryimage77.png";
import subcategoryimage78 from "../../assets/images/products/subcategoryimage78.png";
import subcategoryimage79 from "../../assets/images/products/subcategoryimage79.png";
import subcategoryimage80 from "../../assets/images/products/subcategoryimage80.png";
import subcategoryimage81 from "../../assets/images/products/subcategoryimage81.png";
import subcategoryimage82 from "../../assets/images/products/subcategoryimage82.png";
import subcategoryimage83 from "../../assets/images/products/subcategoryimage83.png";
import subcategoryimage84 from "../../assets/images/products/subcategoryimage84.png";
import subcategoryimage85 from "../../assets/images/products/subcategoryimage85.png";
import subcategoryimage86 from "../../assets/images/products/subcategoryimage86.png";
import subcategoryimage87 from "../../assets/images/products/subcategoryimage87.png";
import subcategoryimage88 from "../../assets/images/products/subcategoryimage88.png";
import subcategoryimage89 from "../../assets/images/products/subcategoryimage89.png";
import subcategoryimage90 from "../../assets/images/products/subcategoryimage90.png";
import subcategoryimage91 from "../../assets/images/products/subcategoryimage91.png";
import subcategoryimage92 from "../../assets/images/products/subcategoryimage92.png";
import subcategoryimage93 from "../../assets/images/products/subcategoryimage93.jpeg";
import subcategoryimage94 from "../../assets/images/products/subcategoryimage94.jpeg";
import subcategoryimage95 from "../../assets/images/products/subcategoryimage95.jpeg";
import subcategoryimage96 from "../../assets/images/products/subcategoryimage96.jpeg";
import subcategoryimage97 from "../../assets/images/products/subcategoryimage97.jpeg";
import subcategoryimage98 from "../../assets/images/products/subcategoryimage98.jpg";
import subcategoryimage99 from "../../assets/images/products/subcategoryimage99.jpg";
import subcategoryimage100 from "../../assets/images/products/subcategoryimage100.jpg";
import subcategoryimage101 from "../../assets/images/products/subcategoryimage101.jpg";
import subcategoryimage102 from "../../assets/images/products/subcategoryimage102.jpg";
import subcategoryimage103 from "../../assets/images/products/subcategoryimage103.jpg";
import subcategoryimage104 from "../../assets/images/products/subcategoryimage104.jpg";
import subcategoryimage105 from "../../assets/images/products/subcategoryimage105.jpg";

import maincategory1 from "../../assets/images/products/main-category-1.png";
import maincategory2 from "../../assets/images/products/main-category-2.png";
import maincategory3 from "../../assets/images/products/main-category-3.png";
import maincategory4 from "../../assets/images/products/main-category-4.png";
import maincategory5 from "../../assets/images/products/main-category-5.png";
import maincategory6 from "../../assets/images/products/main-category-6.png";
import maincategory7 from "../../assets/images/products/main-category-7.png";
import maincategory8 from "../../assets/images/products/main-category-8.png";
import maincategory9 from "../../assets/images/products/main-category-9.png";
import maincategory10 from "../../assets/images/products/main-category-10.png";
import maincategory11 from "../../assets/images/products/main-category-11.png";
import maincategory12 from "../../assets/images/products/main-category-12.png";
import maincategory13 from "../../assets/images/products/main-category-13.png";
import maincategory14 from "../../assets/images/products/main-category-14.png";
import maincategory15 from "../../assets/images/products/main-category-15.png";
import maincategory16 from "../../assets/images/products/main-category-16.png";
import maincategory17 from "../../assets/images/products/main-category-17.png";
import maincategory18 from "../../assets/images/products/main-category-18.png";
import maincategory19 from "../../assets/images/products/main-category-19.png";
import maincategory20 from "../../assets/images/products/main-category-20.png";
import maincategory21 from "../../assets/images/products/main-category-21.png";
import maincategory22 from "../../assets/images/products/main-category-22.jpeg";
import maincategory23 from "../../assets/images/products/main-category-23.jpeg";
import maincategory24 from "../../assets/images/products/main-category-24.jpg";
import maincategory25 from "../../assets/images/products/main-category-25.jpg";
import maincategory26 from "../../assets/images/products/main-category-26.jpg";
import maincategory27 from "../../assets/images/products/main-category-27.jpg";
import maincategory28 from "../../assets/images/products/main-category-28.jpeg";

import subcategoryextraimage1 from "../../assets/images/products/sub-category-extra-image-1.png";
import subcategoryextraimage2 from "../../assets/images/products/sub-category-extra-image-2.png";
import subcategoryextraimage3 from "../../assets/images/products/sub-category-extra-image-3.png";
import subcategoryextraimage4 from "../../assets/images/products/sub-category-extra-image-4.png";
import subcategoryextraimage5 from "../../assets/images/products/sub-category-extra-image-5.png";
import subcategoryextraimage6 from "../../assets/images/products/sub-category-extra-image-6.png";
import subcategoryextraimage7 from "../../assets/images/products/sub-category-extra-image-7.png";
import subcategoryextraimage8 from "../../assets/images/products/sub-category-extra-image-8.png";
import subcategoryextraimage9 from "../../assets/images/products/sub-category-extra-image-9.png";
import subcategoryextraimage10 from "../../assets/images/products/sub-category-extra-image-10.png";
import subcategoryextraimage11 from "../../assets/images/products/sub-category-extra-image-11.png";
import subcategoryextraimage12 from "../../assets/images/products/sub-category-extra-image-12.png";
import subcategoryextraimage13 from "../../assets/images/products/sub-category-extra-image-13.png";
import subcategoryextraimage14 from "../../assets/images/products/sub-category-extra-image-14.png";
import subcategoryextraimage15 from "../../assets/images/products/sub-category-extra-image-15.png";
import subcategoryextraimage16 from "../../assets/images/products/sub-category-extra-image-16.png";
import subcategoryextraimage17 from "../../assets/images/products/sub-category-extra-image-17.png";
import subcategoryextraimage18 from "../../assets/images/products/sub-category-extra-image-18.png";
import subcategoryextraimage19 from "../../assets/images/products/sub-category-extra-image-19.png";
import subcategoryextraimage20 from "../../assets/images/products/sub-category-extra-image-20.png";
import subcategoryextraimage21 from "../../assets/images/products/sub-category-extra-image-21.png";
import subcategoryextraimage22 from "../../assets/images/products/sub-category-extra-image-22.png";
import subcategoryextraimage23 from "../../assets/images/products/sub-category-extra-image-23.png";
import subcategoryextraimage24 from "../../assets/images/products/sub-category-extra-image-24.png";
import subcategoryextraimage25 from "../../assets/images/products/sub-category-extra-image-25.png";
import subcategoryextraimage26 from "../../assets/images/products/sub-category-extra-image-26.png";
import subcategoryextraimage27 from "../../assets/images/products/sub-category-extra-image-27.png";
import subcategoryextraimage28 from "../../assets/images/products/sub-category-extra-image-28.png";
import subcategoryextraimage29 from "../../assets/images/products/sub-category-extra-image-29.png";
import subcategoryextraimage30 from "../../assets/images/products/sub-category-extra-image-30.png";
import subcategoryextraimage31 from "../../assets/images/products/sub-category-extra-image-31.png";
import subcategoryextraimage33 from "../../assets/images/products/sub-category-extra-image-33.png";

import storeIcon1 from "../../assets/images/structure/store-icon1.png";
import storeIcon2 from "../../assets/images/structure/store-icon2.png";
import storeIcon3 from "../../assets/images/structure/store-icon3.png"; 

import ovenhomeappliance from "../../assets/images/products/ovenhomeappliance.jpg";
import wallpainting from "../../assets/images/products/wall-painting.jpg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Home = () => {
   const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const forClient = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const forService = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const ourClienSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const CategorySlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const CategorySlider1 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 200,
      behavior: "smooth", // Smooth scrolling behavior
    });
  };

  const [isCategoriesVisible1, setIsCategoriesVisible1] = useState(false);

  const toggleSection1 = () => {
    setIsCategoriesVisible1(!isCategoriesVisible1);
  };

  const [isCategoriesVisible2, setIsCategoriesVisible2] = useState(false);

  const toggleSection2 = () => {
    setIsCategoriesVisible2(!isCategoriesVisible2);
  };

  const [isCategoriesVisible3, setIsCategoriesVisible3] = useState(false);

  const toggleSection3 = () => {
    setIsCategoriesVisible3(!isCategoriesVisible3);
  };

  const [isCategoriesVisible4, setIsCategoriesVisible4] = useState(false);

  const toggleSection4 = () => {
    setIsCategoriesVisible4(!isCategoriesVisible4);
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const [isExpanded2, setIsExpanded2] = useState(false);
  const toggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const [isExpanded3, setIsExpanded3] = useState(false);
  const toggleContent3 = () => {
    setIsExpanded3(!isExpanded3);
  };

  const [isExpanded4, setIsExpanded4] = useState(false);
  const toggleContent4 = () => {
    setIsExpanded4(!isExpanded4);
  };
  const handleChange = (e) => {
    setError({
      ...error,
      [e.target.name]: "",
    });
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async () => {
    try {
      const isValid = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (!data?.email || !data?.message || !data?.name || !data?.phone||!isValid.test(data?.email)) {
        let errors = {};
        if(!isValid.test(data?.email)){
          errors.email = "Email is invalid";
        }
        if (!data?.email) {
          errors.email = "Email is required";
        }
        if (!data?.message) {
          errors.message = "Message is required";
        }
        if (!data?.name) {
          errors.name = "Name is required";
        }
        if (!data?.phone) {
          errors.phone = "Phone is required";
        }
        setError(errors);
        return;
      }
      setLoading(true);

      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + "user-query",
        data
      );
      toast.success(res?.data?.message);
      setLoading(false);
      setData({
        name: "",
        email: "",
        phone: "",
        message: "",
      })
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />
      <div className="main-content-wrapper">
        <section className="home-section1">
          <div className="container">
            <div className="grid align-items-center">
              <div className="hm-banner-left-content">
                {/* <div className="fixello-app-chips flex align-items-center justify-content-start">
                  <img src={appCircle} alt="Fixello App" /> {t("fixelloApp}
                  <img src={flwr} alt="..." className="btnflwr" />
                </div> */}
                <h1 className="h1 hm-banner-heading mt-4">
                  {t("aReliableSolutionForYourService")}{" "}
                  <span className="orange-span">{t("needs")}</span>
                </h1>
                <p className="p1 mt-3">
                  {t("weProvideMoreThan")}{" "}
                  <span style={{ fontFamily: "Poppins-Bold" }}> 100+ </span>{" "} 
                  {t("servicesAvailable")}{" "} <br></br>
                  {t("theExcellenceExpertiseAndService")}
                </p>
                <div className="flex align-items-center flex-wrap mt-5">
                  <Link to="https://play.google.com/store/apps/details?id=com.si.fixello.fixello_user_mobile" className="storeIcon-a mr-2"><img src={storeIcon1} alt="..." className="storeIcon" /></Link>
                  <Link to="https://apps.apple.com/in/app/fixello-%D9%81%D9%8A%D9%83%D8%B3%D9%84%D9%88/id6499427966" className="storeIcon-a mr-2"><img src={storeIcon2} alt="..." className="storeIcon" /></Link>
                  <Link to="/" className="storeIcon-a mr-2"><img src={storeIcon3} alt="..." className="storeIcon" /></Link>
                </div>
              </div>
              <img
            src={iphonemultiple}
            alt="..."
            className="home-banner-right-image"
          />
            </div>
          </div>
          
          <img
            src={homebannerbgimages}
            alt="..."
            className="homebannerbgimages"
          />
        </section>
        <section className="home-section2" id="whatwedo">
          <div className="container">
            <div className="what-we-do-sectoion flex flex-wrap">
              <div className="w-w-d-image-box">
                <img
                  src={whatwedoimages}
                  alt="..."
                  className="whatwedoimages"
                />
                <img src={flwr} alt="..." className="btnflwr" />
              </div>
              <div className="w-w-d-content-box">
                <h2 className="h2 mb-3">{t("whatWeDo")}</h2>
                <h4 className="h4 mb-3">
                  {t("fixelloIsWhereExpertiseMeetsNeeds")}
                </h4>
                <ul className="pl-0 listul">
                  <li className="listli">
                    <img src={listIcon} alt="..." className="listIcon" />
                    {t("atFixelloWeKnowHowHard")}
                  </li>
                  <li className="listli">
                    <img src={listIcon} alt="..." className="listIcon" />
                    {t("weAlsoKnowHowMuchQuality")}
                  </li>
                  <li className="listli">
                    <img src={listIcon} alt="..." className="listIcon" />
                    {t("valueQualityAndConvenience")}
                  </li>
                </ul>
                <h4 className="h4 mb-3">
                  {t("bringingServicesToYourDoorstep")}{" "}
                </h4>
                <p className="p3">{t("fIXELLOConnectsYouWithAWideRange")}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="home-section3">
          <div className="container">
            <div className="grid">
              <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                <div className="fixello-personal-box">
                  <img
                    src={fixelloPersonalIcon}
                    alt="Personal Icon"
                    className="fixello-per-cor-Icon"
                  />
                  <h2 className="h2 mb-0">{t("fixelloPersonal")}</h2>
                  <p className="p2 mb-3">
                    {t("fixelloMaintenanceServicesForIndividuals")}
                  </p>
                  <ul className="pl-0 listul">
                    <li className="listli">
                      <img src={listIcon} alt="..." className="listIcon" />
                      <span className="arTextBold">{t("ourApplicationSavesYourTime")}</span> {t("ourAppWillSaveYourTime")}
                    </li>
                    <li className="listli">
                      <img src={listIcon} alt="..." className="listIcon" />
                      <span className="arTextBold">{t("browseThrough")}</span> {t("browseThroughSkilledServiceProviders")}
                    </li>
                    <li className="listli">
                      <img src={listIcon} alt="..." className="listIcon" />
                      <span className="arTextBold">{t("sayGoodBy")}</span> {t("sayGoodbyeToWaiting")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                <div className="fixello-personal-box">
                  <img
                    src={fixelloCorporateIcon}
                    alt="Personal Icon"
                    className="fixello-per-cor-Icon"
                  />
                  <h2 className="h2 mb-0">{t("fixelloCorporate")}</h2>
                  <p className="p2 mb-3">
                    {t("fixelloServicesAndPackagesForBusinesses")}
                  </p>
                  <ul className="pl-0 listul">
                    <li className="listli">
                      <img src={listIconBlue} alt="..." className="listIcon" />
                      {t("fixelloOffersAWorldOfTailoredService")}
                    </li>
                    <li className="listli">
                      <img src={listIconBlue} alt="..." className="listIcon" />
                      {t("fromEssentialRepairsToSpecializedExpertise")}
                    </li>
                    <li className="listli">
                      <img src={listIconBlue} alt="..." className="listIcon" />
                      {t("contactUsForMoreInformation")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home-section4" id="categories">
          <div>
            <div className="container">
              <div className="grid">
                <div className="lg:col-11 md:col-10 sm:col-12 col-12">
                  <h1 className="h1">{t("ourCategories")}</h1>
                  <p className="p2 mb-6">
                    {t("categoriesOrganizeDiverseItems")}
                  </p>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="flex align-items-center justify-content-between mb-4">
                <h2 className="h2">{t("homeMaintenance")}</h2>
                <Button className="btn w-100 h-36" onClick={toggleContent}>
                  {" "}
                  {isExpanded ? t("less") : t("seeMore")}{" "}
                  <img src={doubleArrow} alt="..." className="btnarrow" />
                </Button>
              </div>
            </div>
            <div
              className={isExpanded ? "expandedContent" : "collapsedContent"}
            >
              <div className="container container-1165 mainCategories">
                <div className="categorySlider sliderArrowWhitecircle">
                  <Slider {...CategorySlider}>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={subcategoryextraimage33}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("aCCleaningGasFilling")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory27}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("plumbingWork")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory22}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("electricalWork")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory4}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("satelliteTechnician")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory5}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("homeAppliances")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory28}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("carpentryWork")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory25}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("aluminumWork")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory8}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("paintingDecoration")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory9}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("swimmingPools")}
                        </p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>

              <div className="expandedCategories">
                <div className="container mt-5">
                  <h3 className="h3 mb-4">{t("acServices")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage33}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("aCCleaningGasFilling")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={categoryimage3}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("aCDuctCleaning")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={categoryimage2}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("aCInstallation")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={categoryimage4}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("acRepair")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage94}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("aCTransfer")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("plumbingServices")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage1}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("blocksLeakages")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage104}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("mixersTaps")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage3}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("basinsSinks")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage4}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("toiletSanitaryWorks")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage2}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("bathroomShowers")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage3}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("waterHeaters")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage96}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("waterPumps")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage5}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("pipeline")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage95}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("waterFilter")}{" "}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("electricalWorks")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage97}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("wiresNetworksExtension")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage6}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("panelBoardBoxesCutters")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage93}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("lighting")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage8}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("switchesPlugss")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage7}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("fansExhaustFans")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage8}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("waterHeaters")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage98}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("waterPumps")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage105}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("appliancesInstallation")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("satelliteTechnician")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage9}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("newInstallation")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage10}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("maintenanceRepair")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage11}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("programmingChannels")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage12}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("centralSatelliteSystem")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("homeAppliancesSub")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage13}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("washingMachines")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage14}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("cookers")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage99}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("fridges")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={ovenhomeappliance}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">{t("ovens")}</p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("carpentryWork")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage17}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("furniture")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage18}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("kitchens")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage19}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("doors")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage20}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("windows")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("aluminumWork")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage21}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("aluminiumWindowServices")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage22}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("aluminumKitchenServices")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage23}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("aluminumDoorsServices")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage24}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("handrailStairsServices")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage11}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("weldingDoorsAndWindows")}{" "}
                          </p>
                        </div>
                      </div>
                   
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("paintingDecorationSub")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage25}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("gypsumBoard")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage101}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("fillingCracksHoles")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage27}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("wallpaper")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={wallpainting}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("wallsPainting")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage15}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("metalPainting")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage16}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("woodPainting")}{" "}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("swimmingPools")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage29}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("newInstallationSub")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage30}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("lightingIssues")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage31}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("pumpsIssues")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage32}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("filtersIssues")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage17}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("leaks")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage18}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("tilesIssues")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage19}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("cleaning")}{" "}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="container">
              <div className="flex align-items-center justify-content-between mb-4">
                <h2 className="h2">{t("homeServices")} </h2>
                <Button className="btn w-100 h-36" onClick={toggleContent2}>
                  {" "}
                  {isExpanded2 ? t("less") : t("seeMore")}{" "}
                  <img src={doubleArrow} alt="..." className="btnarrow" />
                </Button>
              </div>
            </div>
            <div
              className={isExpanded2 ? "expandedContent" : "collapsedContent"}
            >
              <div className="container container-1165 mainCategories">
                <div className="categorySlider sliderArrowWhitecircle">
                  <Slider {...CategorySlider}>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory10}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("tankCleaning")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory11}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("pestControl")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory12}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("cleaningHomeMaids")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory13}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("agricultureWork")}
                        </p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
              <div className="expandedCategories">
                <div className="container">
                  <h3 className="h3 mb-4">{t("tankCleaning")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage33}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("cleaningConcreteTanksCleaning")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage34}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("cleaningFiberglassTanksCleaning")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage35}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("treatmentAndsprayCleaning")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage36}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("vacuuming")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("pestControl")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage37}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("commercialPestControl")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage38}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("insects")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage39}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("reptiles")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage40}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("rodent")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("cleaningServices")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage41}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("hourlyCleaning")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage42}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("commercialCleaning")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage43}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("ironing")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage44}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("sofaCleaning")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("agricultureWork")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage45}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("arrangeTrim")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage46}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("newAgriculture")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage47}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("sprayingOfPesticides")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage48}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("landscape")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("furnitureCurtains")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage49}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("sofaUpholstery")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage50}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("curtains")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage51}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("fallingPapers")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage52}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("rugsCarpetsSub")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage20}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("arabSession")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage21}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("parquet")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("furnitureMoving")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage53}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("dismantlingAndMovingFurniture")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage54}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {
                              t("transportingAndPackingClothesAndKitchenUtensils")
                            }
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage55}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("oldFurnitureRemoval")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage56}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("wrapping")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="container">
              <div className="flex align-items-center justify-content-between mb-4">
                <h2 className="h2">{t("buildingAndConstruction")} </h2>
                <Button className="btn w-100 h-36" onClick={toggleContent4}>
                  {" "}
                  {isExpanded4 ? t("less") : t("seeMore")}{" "}
                  <img src={doubleArrow} alt="..." className="btnarrow" />
                </Button>
              </div>
            </div>
            <div
              className={isExpanded4 ? "expandedContent" : "collapsedContent"}
            >
              <div className="container container-1165 mainCategories">
                <div className="categorySlider sliderArrowWhitecircle">
                  <Slider {...CategorySlider}>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory18}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("buildingAndConstruction")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory19}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("insulation")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={subcategoryimage59}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("wallPlaster")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory21}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("roofInsulation")}
                        </p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
              <div className="expandedCategories">
                <div className="container">
                  <h3 className="h3 mb-4">{t("buildingAndConstruction")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage57}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("marbleWorks")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage58}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("tileWorks")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage59}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("wallPlaster")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage60}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("buildingAdditions")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("insulation")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage61}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("roofInsulation")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage62}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("insulatePools")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage63}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("floorInsulation")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage64}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("waterTanksInsulation")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage30}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("wallInsulation")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage31}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("shinkoInsulation")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="container">
              <div className="flex align-items-center justify-content-between mb-4">
                <h2 className="h2">{t("other")}</h2>
                <Button className="btn w-100 h-36" onClick={toggleContent3}>
                  {" "}
                  {isExpanded3 ? t("less") : t("seeMore")}{" "}
                  <img src={doubleArrow} alt="..." className="btnarrow" />
                </Button>
              </div>
            </div>
            <div
              className={isExpanded3 ? "expandedContent" : "collapsedContent"}
            >
              <div className="container container-1165 mainCategories">
                <div className="categorySlider sliderArrowWhitecircle">
                  <Slider {...CategorySlider}>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory23}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("restaurantEquipment")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory15}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("carService")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory24}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("handyman")}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="categorBox">
                        <div className="categroyimagebox">
                          <img
                            src={maincategory17}
                            alt="..."
                            className="categroyimage"
                          />
                        </div>
                        <p className="p2 text-center mt-3">
                          {t("computerServices")}
                        </p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
              <div className="expandedCategories">
                <div className="container">
                  <h3 className="h3 mb-4">{t("restaurantEquipment")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage65}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">{t("ovens")}</p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage66}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("foodChiller")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage67}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("largeRefrigeratorsAboveZeroAndBelowZero")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage68}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("iceMaker")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage22}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("shawarmaMachine")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("carServices")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage69}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("engineOilFilter")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage70}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {" "}
                            {t("changeBattery")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage71}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("brakePads")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage72}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("coolingInspectionCycle")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("carWashPolish")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage73}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("exteriorWashing")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage74}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("interiorWashing")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage75}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("exteriorPolishing")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage76}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("interiorPolishing")}
                          </p>
                        </div>
                      </div>
                      {/* <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img src={subcategoryextraimage23} alt="..." className="categroyimage" />
                          </div>
                          <p className="p2 text-center mt-3">{t("interiorPolish} </p>
                        </div>
                      </div> */}
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("handymanSub")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage77}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("tVInstallation")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage100}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("curtainInstallationAndRemovalSub")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage102}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("installationAndRemovalOfCabinetsAndDesks")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage103}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("installationAndRemovalOfWallArt")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage24}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("wallClockInstallationAndRemoval")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">{t("computerServicesSub")}</h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage81}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("laptopAndDesktopRepair")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage82}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {" "}
                            {t("graphicsCardsRepair")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage83}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("windowsRepair")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage84}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("MacSystemRepair")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage25}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("restoreSeletedFilesService")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage26}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("periodicComputerMaintenance")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">
                    {t("internetAndNetworkingServices")}
                  </h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage85}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("configureANewNetwork")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage86}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {" "}
                            {t("maintenanceOfAnExistingMetwork")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage87}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("addingOrMaintainingPrintServices")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage88}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {
                              t("boostInternetTransmissionForWirelessNetwork")
                            }
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage27}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("installANewInternetModem")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage28}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("modemProgramming")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryextraimage29}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("installingAnInternetModemBooster")}{" "}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="container container mt-5">
                  <h3 className="h3 mb-4">
                    {t("surveillanceCameraServices")}
                  </h3>
                </div>
                <div className="container container-1165">
                  <div className="categorySlider sliderArrowWhitecircle">
                    <Slider {...CategorySlider1}>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage89}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("newInstallation")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage90}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {" "}
                            {t("repairSetup")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage91}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("commercailSurvillanceCameraService")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="categorBox">
                          <div className="categroyimagebox">
                            <img
                              src={subcategoryimage92}
                              alt="..."
                              className="categroyimage"
                            />
                          </div>
                          <p className="p2 text-center mt-3">
                            {t("dVRService")}
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home-section5">
          <div className="container">
            <div className="grid">
              <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                <h2 className="h2 mb-3">{t("whyFixello")}</h2>
                <p className="p2 mb-3">
                  {t("fixelloAppGuaranteesAnEasyExperience")}
                </p>
              </div>
            </div>
            <Accordion activeIndex={0}>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <img
                      src={whyfixelloicon1}
                      alt="..."
                      className="whyfixelloIcon"
                    />
                    <span className="vertical-align-middle">
                      {t("smoothExperience")}
                    </span>
                  </div>
                }
              >
                <p className="m-0 p3">{t("getThingsDoneEasily")}</p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <img
                      src={whyfixelloicon2}
                      alt="..."
                      className="whyfixelloIcon"
                    />
                    <span className="vertical-align-middle">
                      {t("oneStopeSolution")}
                    </span>
                  </div>
                }
              >
                <p className="m-0 p3">{t("allInOneAppThatProvides")}</p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <img
                      src={whyfixelloicon3}
                      alt="..."
                      className="whyfixelloIcon"
                    />
                    <span className="vertical-align-middle">
                      {t("qualifiedExperts")}
                    </span>
                  </div>
                }
              >
                <p className="m-0 p3">
                  {t("ourProfessionalExpertsAreCarefullySelected")}
                </p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <img
                      src={whyfixelloicon4}
                      alt="..."
                      className="whyfixelloIcon"
                    />
                    <span className="vertical-align-middle">
                      {t("youChooseTheBestPrices")}
                    </span>
                  </div>
                }
              >
                <p className="m-0 p3">{t("ourUniqueAuctionSystemLets")}</p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <img
                      src={whyfixelloicon5}
                      alt="..."
                      className="whyfixelloIcon"
                    />
                    <span className="vertical-align-middle">
                      {t("youWillBeSatisfied")}
                    </span>
                  </div>
                }
              >
                <p className="m-0 p3">{t("yourSatisfactionIsOurFuel")}</p>
              </AccordionTab>
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <img
                      src={whyfixelloicon6}
                      alt="..."
                      className="whyfixelloIcon"
                    />
                    <span className="vertical-align-middle">
                      {t("suitableForBusinessesAndIndividuals")}
                    </span>
                  </div>
                }
              >
                <p className="m-0 p3">{t("fixelloIsFlexible")}</p>
              </AccordionTab>
            </Accordion>
          </div>
        </section>
        <section className="home-section6">
          <div className="container">
            <div className="grid">
              <div className="lg:col-4 md:col-12 sm:col-12 col-12">
                <h2 className="h2 mb-3">{t("howItWorks")}</h2>
                <p className="p2 mb-6">{t("easyAs")}</p>
              </div>
            </div>
          </div>
          <div className="container container-1269">
            <div className="grid forsliderRow">
              <div className="lg:col-4 md:col-12 sm:col-12 col-12">
                <div className="sliderFor">
                  <Slider {...forClient}>
                    <div>
                      <div className="forClientSlides">
                        <img
                          src={clientPhoneImage1}
                          alt="..."
                          className="clientPhoneImage"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="forClientSlides">
                        <img
                          src={clientPhoneImage2}
                          alt="..."
                          className="clientPhoneImage"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="forClientSlides">
                        <img
                          src={clientPhoneImage3}
                          alt="..."
                          className="clientPhoneImage"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="forClientSlides">
                        <img
                          src={clientPhoneImage4}
                          alt="..."
                          className="clientPhoneImage"
                        />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
              <div className="lg:col-8 md:col-12 sm:col-12 col-12 flex align-items-center">
                <div className="forClient-content">
                  <h2 className="h2 mb-1">{t("forClients")}</h2>
                  <p className="p2 mb-4">
                    {t("fixellosProcessConnectsYouToProfessional")}:
                  </p>
                  <div className="mb-3">
                    <h6 className="h6 mb-2 flex align-items-center">
                      <img src={orderIcon} alt="..." className="forIcon" />
                      {t("order")}
                    </h6>
                    <p className="p3">
                      {t("postYourOrderAndWriteADetailed")}
                    </p>
                  </div>
                  <div className="mb-3">
                    <h6 className="h6 mb-2 flex align-items-center">
                      <img src={chooseIcon} alt="..." className="forIcon" />
                      {t("choose")}
                    </h6>
                    <p className="p3">
                      {t("receiveTheOffersFromTheProviders")}
                    </p>
                  </div>
                  <div className="mb-3">
                    <h6 className="h6 mb-2 flex align-items-center">
                      <img src={injoyIcon} alt="..." className="forIcon" />
                      {t("enjoy")}
                    </h6>
                    <p className="p3">{t("sitBackWhiletheProfessional")}</p>
                  </div>

                  <div className="grayAlertBox mt-4">
                    <ul className="pl-0 listul">
                      <li className="listli mb-0">
                        <img
                          src={listIconBlue}
                          alt="..."
                          className="listIcon"
                        />
                        {t("dontforgetToRateTheService")}
                      </li>
                    </ul>
                  </div>
                  <div className="flex align-items-center flex-wrap mt-5">
                    <Link to="https://play.google.com/store/apps/details?id=com.si.fixello.provider"><img src={storeIcon1} alt="..." className="storeIcon mr-2" /></Link>
                    <Link to="https://apps.apple.com/in/app/fixello-provider-%D9%81%D9%8A%D9%83%D8%B3%D9%84%D9%88-%D8%B4%D8%B1%D9%83%D8%A7%D8%AA/id6499427937"><img src={storeIcon2} alt="..." className="storeIcon mr-2" /></Link>
                    <Link to="/"><img src={storeIcon3} alt="..." className="storeIcon mr-2" /></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid forsliderRow forServiceProviders">
              <div className="lg:col-8 md:col-12 sm:col-12 col-12 flex align-items-center">
                <div className="forClient-content">
                  <h2 className="h2 mb-4">{t("forServiceProviders")}</h2>
                  <div className="mb-3">
                    <h6 className="h6 mb-2 flex align-items-center">
                      <img src={signupIcon} alt="..." className="forIcon" />
                      {t("signUp")}
                    </h6>
                    <p className="p3">{t("registerAsAServiceProvide")}</p>
                  </div>
                  <div className="mb-3">
                    <h6 className="h6 mb-2 flex align-items-center">
                      <img src={browseIcon} alt="..." className="forIcon" />
                      {t("browse")}
                    </h6>
                    <p className="p3">
                      {t("browseAndCheckOutDifferentOrders")}
                    </p>
                  </div>
                  <div className="mb-3">
                    <h6 className="h6 mb-2 flex align-items-center">
                      <img src={placeOfferIcon} alt="..." className="forIcon" />
                      {t("placeYourOffer")}
                    </h6>
                    <p className="p3">{t("chooseAnOrderPlace")}</p>
                  </div>

                  <div className="grayAlertBox mt-4">
                    <ul className="pl-0 listul">
                      <li className="listli mb-0">
                        <img
                          src={listIconBlue}
                          alt="..."
                          className="listIcon"
                        />
                        {t("afterYouGetAccepted")}
                      </li>
                    </ul>
                  </div>
                  <div className="flex align-items-center flex-wrap mt-5">
                    <Link to="/"><img src={storeIcon1} alt="..." className="storeIcon mr-2" /></Link>
                    <Link to="/"><img src={storeIcon2} alt="..." className="storeIcon mr-2" /></Link>
                    <Link to="/"><img src={storeIcon3} alt="..." className="storeIcon mr-2" /></Link>
                  </div>
                </div>
              </div>
              <div className="lg:col-4 md:col-12 sm:col-12 col-12 ml-auto">
                <div className="sliderFor">
                  <Slider {...forService}>
                    <div>
                      <div className="forClientSlides">
                        <img
                          src={servicePhoneImage1}
                          alt="..."
                          className="clientPhoneImage"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="forClientSlides">
                        <img
                          src={servicePhoneImage2}
                          alt="..."
                          className="clientPhoneImage"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="forClientSlides">
                        <img
                          src={servicePhoneImage3}
                          alt="..."
                          className="clientPhoneImage"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="forClientSlides">
                        <img
                          src={servicePhoneImage4}
                          alt="..."
                          className="clientPhoneImage"
                        />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="home-section7">
          <div className="container container-1216">
            <h2 className="h2">{t("ourSatisfiedClients}</h2>
            <p className="p2 mb-5">{t("happyClientsOurBestBusinessAsset}</p>
          </div>
          <div className="container container-1366">
            <div className="ourclientssliderRow sliderArrowWhitecircle">
              <Slider {...ourClienSlider}>
                <div>
                  <div className="ourClientDetail">
                    <div className="flex align-items-center">
                      <img src={clientImage1} alt="..." className="clientImage" />
                      <div className="pl-3">
                        <h6 className="h6">Ethel Johnston</h6>
                        <p className="p3 mb-0">Human Directives Director</p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p className="p3">Fuga et debitis numquam omnis sed explicabo rem. Temporibus aut earum harum sint enim quia sit. Odit blanditiis illum amet doloribus adipisci corrupti explicabo. Qui non omnis eum consequatur voluptas aut ut dolor aut.</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="ourClientDetail">
                    <div className="flex align-items-center">
                      <img src={clientImage2} alt="..." className="clientImage" />
                      <div className="pl-3">
                        <h6 className="h6">Esther Hills</h6>
                        <p className="p3 mb-0">Lead Intranet Technician</p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p className="p3">Omnis totam molestiae delectus nemo alias nesciunt harum et. Nobis dolorum excepturi quod vel. Sunt est qui ab non dolores repellat rem impedit dolores. Ut ea rerum cum eum. Alias dolores tempore illo accusantium est et voluptatem voluptas.</p>
                    </div>
                  </div>
                </div>
                
                <div>
                  <div className="ourClientDetail">
                    <div className="flex align-items-center">
                      <img src={clientImage1} alt="..." className="clientImage" />
                      <div className="pl-3">
                        <h6 className="h6">Ethel Johnston</h6>
                        <p className="p3 mb-0">Human Directives Director</p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p className="p3">Fuga et debitis numquam omnis sed explicabo rem. Temporibus aut earum harum sint enim quia sit. Odit blanditiis illum amet doloribus adipisci corrupti explicabo. Qui non omnis eum consequatur voluptas aut ut dolor aut.</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="ourClientDetail">
                    <div className="flex align-items-center">
                      <img src={clientImage2} alt="..." className="clientImage" />
                      <div className="pl-3">
                        <h6 className="h6">Esther Hills</h6>
                        <p className="p3 mb-0">Lead Intranet Technician</p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p className="p3">Omnis totam molestiae delectus nemo alias nesciunt harum et. Nobis dolorum excepturi quod vel. Sunt est qui ab non dolores repellat rem impedit dolores. Ut ea rerum cum eum. Alias dolores tempore illo accusantium est et voluptatem voluptas.</p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>        
          </div>
        </section> */}
        <section className="home-section8 pt-2" id="contactus">
          <div className="container">
            <div className="contactUsBox">
              <div className="grid">
                <div className="lg:col-6 md:col-12 sm:col-12 col-12 ml-auto">
                  <div className="contactDEatil">
                    <h6 className="h6 mb-3">
                      {t("haveAnyQuestionsFeedbackOrNeedAssistance")}
                    </h6>
                    <p className="p3 mb-5">{t("weAreHereToHelpReach")}</p>
                    <div className="flex align-items-center mb-3">
                      <img
                        src={locationIcon}
                        alt="..."
                        className="contactICon"
                      />
                      <p className="p3 mb-0 pl-3">{t("alAddress")}</p>
                    </div>
                    <div className="flex align-items-center mb-3">
                      <img src={emailIcon} alt="..." className="contactICon" />
                      <p className="p3 mb-0 pl-3">contact@fixello.com</p>
                    </div>
                    <div className="flex align-items-center mb-3">
                      <img
                        src={contactIcon}
                        alt="..."
                        className="contactICon"
                      />
                      <p className="p3 mb-0 pl-3">0097431000649</p>
                    </div>
                  </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 col-12 ml-auto">
                  <div>
                    <div className="formField">
                      <InputText
                        type="text"
                        className="p-inputtext-lg"
                        placeholder={t("name")}
                        name="name"
                        onChange={handleChange}
                        value={data?.name}
                      />
                      <p className="errorMsg">
                        {t(error?.name)}
                      </p>
                    </div>
                    <div className="formField">
                      <InputText
                        type="text"
                        className="p-inputtext-lg"
                        placeholder={t("mobileNumber")}
                        onChange={handleChange}
                        name="phone"
                        value={data?.phone}
                      />
                      <p className="errorMsg">
                        {t(error?.phone)}
                      </p>
                    </div>
                    <div className="formField">
                      <InputText
                        type="text"
                        className="p-inputtext-lg"
                        placeholder={t("email")}
                        onChange={handleChange}
                        name="email"
                        value={data?.email}
                      />
                      <p className="errorMsg">
                        {t(error?.email)}
                      </p>
                    </div>
                    <div className="formField">
                      <InputTextarea
                        autoResize
                        rows={5}
                        cols={30}
                        placeholder={t("message")}
                        onChange={handleChange}
                        name="message"
                        value={data?.message}
                      />
                      <p className="errorMsg">
                        {t(error?.message)}
                      </p>
                    </div>
                    <div>
                      <Button
                        className="btn w-100"
                        onClick={handleSubmit}
                        style={{ width: "100%" }}
                        disabled={loading}
                      >
                        {t("send")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <a href="https://api.whatsapp.com/send?phone=97431000649" target="blank"><img src={whatsapp} alt="..." className="whatsapp" /></a>
      </div>
      <Footer />
      <div className="scroll-to-top-button">
        <Button
          icon="pi pi-arrow-up"
          className="p-button-raised p-button-rounded"
          onClick={scrollToTop}
        />
      </div>
    </>
  );
};

export default Home;
